<template>
  <div>
    <div class="title">我的收藏</div>
    <div class="content">
      <div class="flex" style="margin-bottom: 48px">
        <el-input
          class="ipt"
          v-model="input"
          placeholder="输入学习课程名称"
        ></el-input>
        <div class="search" @click="Submit">搜索</div>
      </div>
      <div class="list" v-if="list.length > 0">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click.stop="godetail(item)"
        >
          <div class="flex">
            <img :src="item.pic" alt="" />
            <div class="item_1">
              <div class="item_1_1">
                {{ item.title }}
              </div>
              <div class="item_1_2">
                {{ item.stu }}人已学 &nbsp;&nbsp;&nbsp;&nbsp; 学习时长：{{
                  item.ks
                }}课时 &nbsp;&nbsp;&nbsp;&nbsp;已收藏
              </div>
            </div>
          </div>
          <div>
            <div class="item_2" @click="cancel(item)">取消收藏</div>
          </div>
        </div>
      </div>
      <img
        v-if="list.length == 0"
        class="img"
        src="../../assets/zanwushoucang_icon.png"
        alt=""
      />
      <div v-if="list.length == 0" class="txt">暂无收藏</div>
      <div style="height: 500px"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      list: [],
      input: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    ...mapActions(["getCollection", "getCancelCollection"]),
    //搜索
    Submit() {
      this.getCollection({
        username: this.userInfo.username,
        page: 1,
      }).then((res) => {
        if (res.code == 200) {
          if (this.input != "") {
            let list = [];
            res.data.list.forEach((e) => {
              // let title = e.title;
              let arr = "";
              arr = e.title.indexOf(this.input);
              if (arr != -1) {
                list.push(e);
              }
            });
            this.list = list;
          } else {
            this.list = res.data.list;
          }
        }
      });
    },
    //跳转详细
    godetail(item) {
      this.$router.push({
        path: "/coursedetails",
        query: {
          id: item.id,
          type: "我的收藏",
        },
      });
    },
    //取消收藏
    cancel(item) {
      this.getCancelCollection({
        id: item.id,
        username: this.userInfo.username,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "取消成功",
            type: "success",
          });
          this.search();
        }
      });
    },
    search() {
      this.getCollection({
        username: this.userInfo.username,
        page: 1,
      }).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list;
        }
      });
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.content {
  margin-top: 14px;
  padding: 40px;
  box-sizing: border-box;
  background-color: #fff;
  .ipt {
    width: 384px;
    height: 35px;
    background: #ffffff;
    border: 0px none;
  }
  .search {
    cursor: pointer;
    margin-left: 14px;
    text-align: center;
    background-color: #edf5fe;
    width: 93px;
    height: 40px;
    line-height: 40px;
    border: 2px solid #d8e1ec;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7c7c7c;
  }
  .list {
    .item {
      cursor: pointer;
      margin-bottom: 33px;
      border-bottom: 1px solid #e1e1e1;
      height: 115px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      img {
        width: 140px;
        height: 81px;
      }
      .item_1 {
        margin-left: 24px;
        .item_1_1 {
          width: 306px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7c7c7c;
        }
        .item_1_2 {
          margin-top: 12px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;

          color: #7c7c7c;
        }
      }
      .item_2 {
        cursor: pointer;
        margin-top: 59px;
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #137cfb;
      }
    }
  }
  .img {
    display: block;
    margin: 0 auto;
  }
  .txt {
    text-align: center;
    margin-top: 30px;
    color: #ccc;
  }
}
</style>
